(function () {
  if (isTouchDevice()) {
    let specialBtns = document.getElementsByClassName("btn-special");

    for (let i = 0; i < specialBtns.length; i++) {
      var circle = specialBtns[i].getElementsByClassName("circle");
      if (circle != null && circle.lenght != 0) {
        gsap.to(circle[0], {
          scrollTrigger: {
            trigger: specialBtns[i],
            toggleClass: "active",
            start: "top 80%",
            end: "top 20%",
          },
        });
      }
    }
  }
})();
