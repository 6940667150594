(function () {
    let closeBtn = document.getElementById("oxitgen-backlink-close");

    if (closeBtn != null && closeBtn.lenght != 0) {
        let parentElement = closeBtn.parentElement;

            closeBtn.addEventListener("click", function(e) {
                e.preventDefault;
                if(!parentElement.classList.contains("oxitgen-backlink--closed")) { 
                    parentElement.classList.add("oxitgen-backlink--closed");
                    e.stopPropagation();
                }
            });

                parentElement.addEventListener("click", function(e) {
                    if(parentElement.classList.contains("oxitgen-backlink--closed")) { 
                        parentElement.classList.remove("oxitgen-backlink--closed");
                    }
                })            
    }


})();